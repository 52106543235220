import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionRecordService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);
   }

   
   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }
  
   // Create decisionRecords
   createDRecords(user: Object): Observable<Object> {
    console.log("In createdecisionRecords method, DecisionRecords: ", user);
    return this.http.post(`${environment.apiUrl}/decisionRecords`, user).pipe(catchError(this.handleError));
  }

   // Get decisionRecords by Id Get
   getDRecords(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/decisionRecords/${id}`).pipe(catchError(this.handleError));
  }

   //Delete DRecords by Id Delete
   deleteDRecords(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/decisionRecords/${id}`).pipe(catchError(this.handleError));
  }

  
  //Update decisionRecords by Id Put
  updateDRecords(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/decisionRecords/${id}`, user).pipe(catchError(this.handleError));
  }

    //Get All decisionRecords by Id Get
    getAllDRecords(projectId): Observable<Object> {
      let params = new HttpParams();
      params = params.append('filterBy', 'projectId');
      params = params.append('filterByValue', projectId);
      return this.http.get(`${environment.apiUrl}/decisionRecords`, { params: params }).pipe(catchError(this.handleError));
    }

}
